import { useCallback, useEffect, useRef, useState } from "react";
import {
  fetchBreaks,
  fetchTimeCut,
  updateBreaks,
  updateTimeCut,
} from "../services/timeManagementService";

const DEFAULT_BREAKS = [
  {
    name: "display_breaks",
    title: "Afficher les pauses",
    checked: false,
    desc: "Les pauses seront incluses en tant que lignes distinctes dans le fichier export.",
  },
  {
    name: "exclude_breaks",
    title: "Exclure les pauses",
    checked: false,
    desc: "Les pauses seront exclues du fichier export.",
  },
  {
    name: "add_breaks_to_hours_worked",
    title: "Ajouter les pauses aux heures travaillées",
    checked: false,
    desc: "Les pauses seront intégrées directement au total des heures travaillées dans le fichier export.",
  },
];

function useSettingsTimeManagement() {
  const [timeCut, setTimeCut] = useState(0);
  const [breaks, setBreaks] = useState(DEFAULT_BREAKS);
  const [isWaiting, setIsWaiting] = useState(false);

  const timeCutTimeoutRef = useRef(null);

  useEffect(() => {
    const loadData = async () => {
      setIsWaiting(true);

      try {
        const timeCutRes = await fetchTimeCut();
        if (timeCutRes && typeof timeCutRes.timeCut !== "undefined") {
          setTimeCut(timeCutRes.timeCut);
        }

        const breaksRes = await fetchBreaks();
        if (breaksRes && breaksRes.breaks) {
          setBreaks((prev) =>
            prev.map((b) => ({
              ...b,
              checked: b.name === breaksRes.breaks,
            }))
          );
        }
      } catch (error) {
        console.error("Erreur lors du chargement des paramètres :", error);
      } finally {
        setIsWaiting(false);
      }
    };

    loadData();
  }, []);

  const handleTimeCutChange = useCallback((e) => {
    const newValue = e.target.value;
    setTimeCut(newValue);

    if (timeCutTimeoutRef.current) {
      clearTimeout(timeCutTimeoutRef.current);
    }

    timeCutTimeoutRef.current = setTimeout(async () => {
      try {
        await updateTimeCut(newValue);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du timeCut :", error);
      }
    }, 1000);
  }, []);

  const handleBreaksChange = useCallback(
    async (e) => {
      const selectedBreak = e.target.value;

      setBreaks((prev) =>
        prev.map((b) => ({
          ...b,
          checked: b.name === selectedBreak,
        }))
      );

      try {
        await updateBreaks(selectedBreak);
      } catch (error) {
        console.error("Erreur lors de la mise à jour des pauses :", error);
      }
    },
    [setBreaks]
  );

  return {
    timeCut,
    breaks,
    isWaiting,
    handleTimeCutChange,
    handleBreaksChange,
  };
}

export default useSettingsTimeManagement;
