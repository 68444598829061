import React from "react";
import StyledInput from "../styledInput/StyledInput";

function BreaksBlock({ breaks, isWaiting, onBreaksChange }) {
  const checkedBreak = breaks.find((b) => b.checked);

  return (
    <div className="py-3 px-4 flex justify-between gap-4 items-center">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col">
          <div className="font-bold">Pauses dans le fichier Peopulse</div>
          <div>{checkedBreak?.desc}</div>
        </div>

        <div className="flex flex-col max-w-[330px]">
          {isWaiting ? (
            <div className="animate-pulse">
              <div className="h-12 bg-gray-200 rounded w-full"></div>
            </div>
          ) : (
            <StyledInput
              type="select"
              value={checkedBreak?.name}
              list={breaks.map((b) => ({
                value: b.name,
                label: b.title,
              }))}
              onChange={onBreaksChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BreaksBlock;
