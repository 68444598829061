import ApiDatabase from "../server";

export const fetchTimeCut = () => {
  return new Promise((resolve, reject) => {
    ApiDatabase.getTimeCut(
      {},
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
};

export const updateTimeCut = (newValue) => {
  return new Promise((resolve, reject) => {
    ApiDatabase.postUpdateTimeCut(
      { timeCut: newValue },
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
};

export const fetchBreaks = () => {
  return new Promise((resolve, reject) => {
    ApiDatabase.getBreaks(
      {},
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
};

export const updateBreaks = (selectedBreak) => {
  return new Promise((resolve, reject) => {
    ApiDatabase.postUpdateBreaks(
      { breaks: selectedBreak },
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
};
