import { Route, Routes, useNavigate } from "react-router-dom";
import RequireAuth from "../components/requireAuth";
import Activity from "../pages/Activity";
import HourlyTracking from "../pages/HourlyTracking";
import Layout from "../pages/Layout";
import Mission from "../pages/Mission";
import SettingsCompanies from "../pages/SettingsCompanies";
import SettingsTeam from "../pages/SettingsTeam";
import SettingsTimeManagement from "../pages/SettingsTimeManagement";
import Unauthorized from "../pages/Unauthorized";

const ConnectionRoutes = () => {
  const navigate = useNavigate();

  const url = window.location.pathname;
  if (url === "/settings") {
    navigate("/settings/time-management");
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={"hourly-tracking"} element={<HourlyTracking />} />
          <Route path={"hourly-tracking/:id"} element={<Mission />} />
          <Route element={<RequireAuth allowedRoles={[225, 215, 210]} />}>
            <Route path={"activity"} element={<Activity />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[225]} />}>
            <Route
              path={"settings/time-management"}
              element={<SettingsTimeManagement />}
            />
            <Route path={"settings/team"} element={<SettingsTeam />} />
            <Route
              path={"settings/companies"}
              element={<SettingsCompanies />}
            />
          </Route>
          <Route path={"unauthorized"} element={<Unauthorized />} />
        </Route>
      </Routes>
    </>
  );
};

export default ConnectionRoutes;
