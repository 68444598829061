import React from "react";
import PageContainer from "../components/pageContainer/PageContainer";
import SidebarSettings from "../components/sidebars/SidebarSettings";
import useTitle from "../hooks/TitleManager";
import useSettingsTimeManagement from "../hooks/useSettingsTimeManagement";

import BreaksBlock from "../components/timeManagement/BreaksBlock";
import TimeCutBlock from "../components/timeManagement/TimeCutBlock";

const SettingsTimeManagement = () => {
  useTitle({ title: "Paramètres - Gestion des heures & pointages" });

  const {
    timeCut,
    breaks,
    isWaiting,
    handleTimeCutChange,
    handleBreaksChange,
  } = useSettingsTimeManagement();

  return (
    <PageContainer type="settings_time_management">
      <div className="flex gap-6 h-full">
        <SidebarSettings />
        <div className="w-full h-full p-6 overflow-y-auto border rounded-lg flex flex-col gap-4">
          <div className="font-black text-xl">
            Gestion des heures & pointages
          </div>

          {/* Bloc timeCut */}
          <TimeCutBlock
            timeCut={timeCut}
            isWaiting={isWaiting}
            onTimeCutChange={handleTimeCutChange}
          />

          {/* Bloc breaks */}
          <BreaksBlock
            breaks={breaks}
            isWaiting={isWaiting}
            onBreaksChange={handleBreaksChange}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default SettingsTimeManagement;
