import React from "react";
import StyledInput from "../styledInput/StyledInput";

function TimeCutBlock({ timeCut, isWaiting, onTimeCutChange }) {
  return (
    <div className="py-3 px-4 flex justify-between gap-4 items-center">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col">
          <div className="font-bold">Indiquer les coupures</div>
          <div>
            Autoriser le calcul des coupures dès qu’un temps de pause est
            dépassé
          </div>
        </div>

        <div className="flex flex-col max-w-[330px]">
          {isWaiting ? (
            <div className="animate-pulse">
              <div className="h-12 bg-gray-200 rounded w-full"></div>
            </div>
          ) : (
            <StyledInput
              type="number"
              value={timeCut}
              placeholder="0"
              unit="minutes"
              onChange={onTimeCutChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TimeCutBlock;
